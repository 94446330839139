.image-carrousel {
    height: 100vh;
    object-fit: cover;
  }

  .carousel-overlay {
    position: relative;
  }
  
  .carousel-overlay img {
    display: block;
    width: 100%;
  }
  
  .carousel-overlay::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
  }